.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.preview-container {
  position: absolute;
  display: none;
  top: 0;
  width: 25%;
  height: 25%;
}

.preview-debug {
  display: block;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

iframe {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
